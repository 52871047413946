<template>
  <div class="yimiao">
    <div>
      <div style="margin-bottom: 100px;">
        <van-cell-group>
          <van-field v-model="datafrom.HotelName"
            required
            @click="hotelShow = true"
            readonly="readonly"
            label="民宿旅馆"
            placeholder="请选择民宿旅馆" />
          <van-popup v-model="hotelShow"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="hotelList"
              value-key="Name"
              @cancel="hotelShow = false"
              @confirm="hotelShowOrg">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.WorkAddrName"
            required
            @click="clickItem()"
            readonly="readonly"
            label="工作地点"
            placeholder="请选择工作地点" />
          <van-popup v-model="idListOrg"
            :style="{ height: '50%' }"
            round
            position="bottom">
            <h3 style="text-align:center;">请选择工作地点</h3>
            <div style="margin:10px;">
              <van-tree-select :items="items"
                :active-id.sync="activeIds"
                :main-active-index.sync="activeIndex" />
            </div>
            <div style="position: fixed;bottom: 15px;width:100%;">
              <div style="width:100%; text-align: center;">
                <van-button class="button"
                  @click="postclickItem()"
                  style="width:95%;border-radius: 5px;"
                  type="info">确认</van-button>
              </div>
            </div>
          </van-popup>
          <!-- <van-popup v-model="idListOrg"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="OrganList"
              value-key="organname"
              @cancel="idListOrg = false"
              @confirm="onidListOrg">
            </van-picker>
          </van-popup> -->
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Title"
            required
            label="职位名称"
            placeholder="请输入职位名称" />
        </van-cell-group>

        <van-field v-model="datafrom.AbiName"
          readonly
          required
          label="职业类型"
          placeholder="请选择职业类型"
          @click="abilityListShow = true" />
        <van-popup v-model="abilityListShow"
          round
          position="bottom">
          <van-cascader v-model="cascaderValue"
            title="请选择职业类型"
            :options="AbilityList"
            @close="abilityListShow = false"
            @finish="onAbilityListFinish"
            :field-names="fieldNames" />
        </van-popup>
        <van-cell-group>
          <van-field v-model="datafrom.EduName"
            @click="edulShow = true"
            readonly="readonly"
            label="学历"
            placeholder="请选择学历" />
          <van-popup v-model="edulShow"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="eduList"
              value-key="Name"
              @cancel="edulShow = false"
              @confirm="edulShowOrg">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.RecruitNum"
            required
            label="招聘人数"
            placeholder="请输入招聘人数" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.WorkYear"
            label="工作年限"
            required
            placeholder="请输入工作年限" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.SalaryMin"
            label="最低月薪"
            required
            placeholder="请输入最低月薪" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.SalaryMax"
            label="最高月薪"
            required
            placeholder="请输入最高月薪" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.SalaryMemo"
            label="薪资备注"
            placeholder="请输入薪资备注" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.AgeMin"
            label="最小年龄"
            required
            placeholder="请输入最小年龄" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.AgeMax"
            label="最大年龄"
            required
            placeholder="请输入最大年龄" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Describe"
            label="职位描述"
            placeholder="请输入职位描述" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Skill"
            label="技能要求"
            placeholder="请输入技能要求" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.JobRequire"
            label="任职要求"
            placeholder="请输入任职要求" />
        </van-cell-group>

        <div class="filtersBox">
          <div class="labelClass"
            style="padding: 10px 6px 10px 0px;">

            <div>
              <span style="color:red;">* </span>紧急程度
            </div>
          </div>
          <div style="padding: 10px 14px 6px 10px;">
            <van-radio-group v-model="datafrom.Urgency"
              direction="horizontal">
              <van-radio name="1">一般</van-radio>
              <van-radio name="2">紧急</van-radio>
              <van-radio name="3">非常紧急</van-radio>
            </van-radio-group>
          </div>
        </div>
        <van-cell-group>
          <van-field v-model="datafrom.Welfare"
            label="职位福利"
            placeholder="请输入职位福利" />
        </van-cell-group>
        <div class="filtersBox">
          <div class="labelClass"
            style="padding: 10px 6px 10px 0px;">

            <div>
              <span style="color:red;">* </span>状态
            </div>
          </div>
          <div style="padding: 10px 14px 6px 15px;line-height:35px;">
            <van-radio-group v-model="datafrom.Status"
              direction="horizontal">
              <van-radio name="1"
                style="margin-bottom: 12px;">招聘中</van-radio>
              <van-radio name="2"
                style="margin-bottom: 12px;">暂缓招聘</van-radio>
              <van-radio name="3"
                style="margin-bottom: 12px;">招聘结束</van-radio>
            </van-radio-group>
          </div>
        </div>

        <van-cell-group>
          <van-field v-model="datafrom.Remark"
            label="备注"
            placeholder="请输入备注" />
        </van-cell-group>

      </div>
      <div style="position: fixed;bottom: 15px;width:100%;">
        <div style="width:100%; text-align: center;">
          <van-button class="button"
            @click="postfrom(1)"
            style="width:95%;border-radius: 5px;"
            type="info">提交</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";

import {
  HGetJob,
  GetAbilityList,
  HSaveJob,
  HGetMyHotelList,
} from "@/api/shop";
import { GetCodeEntrysList } from "@/api/wangge";
import { HGetMyHotel } from "@/api/RealInfo";
Vue.use(Toast);
import {
  WeGetRegionList,
  WeGetOrganTree,
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      OrganList: [],
      minDate: new Date(2021, 0),
      maxDate: new Date(2025, 0),
      NativeCantonCodedata: [],
      WorkAdd: [],
      cascaderValue: '',
      edulShow: false,
      eduList: [],
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ],
      yimiaolist: [
        { id: "0", Name: "未接种" },
        { id: "1", Name: "第一针" },
        { id: "2", Name: "第二针" },
      ],
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        JobId: 0, //id、
        Title: "",
        AbilityCode: "", // 职业类型编码
        RecruitNum: "", // 招聘人数
        WorkAddrCode: "", // 工作地点编码
        WorkYear: "", // 工作年限
        EduCode: "", // 学历编码
        SalaryMin: "", // 最低月薪
        SalaryMax: "", // 最高月薪
        SalaryMemo: "", // 薪资备注
        AgeMin: "", // 最小年龄
        AgeMax: "", // 最大年龄
        Describe: "", // 职位描述
        Skill: "", // 技能要求
        JobRequire: "", // 任职要求
        Urgency: "", // 紧急程度
        Welfare: "", // 职位福利
        Status: "1",//状态
        Remark: "",//备注
      },
      ImgsList: [],
      shopType: [
        { Id: 1, Name: '五星' },
        { Id: 2, Name: '四星' },
        { Id: 3, Name: '三星' },
        { Id: 4, Name: '二星' },
        { Id: 5, Name: '二星以下' },
      ],
      shopTypePicker: false, //商铺类型
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromBackTime: false,
      datafromOutTime: false,
      bangdingShow: true,
      bjZuoBiao: "",
      shopAdd: this.$route.query.add,
      abilityListShow: false,//职位列表弹窗
      AbilityList: [],//职位列表
      fieldNames: {
        text: 'Name',
        value: 'Coding',
        children: 'Children',
      },
      items: [],
      activeIds: [],
      activeIndex: 0,
      hotelList: [],
      hotelShow: false,
    };
  },
  created () {
    this.getMyHotelList();
    this.GetOrgansNullToken();
    this.zidianlist();
    this.shopXq();
    this.getAbilityList();
    this.datafrom.ShopCate = this.$route.query.shopCate;
  },
  methods: {
    getMyHotelList () {

      HGetMyHotelList({
        openID: getOpenId()
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.hotelList = res.data.data;

          }
        })
    },
    // 选中民宿
    hotelShowOrg (val) {
      this.datafrom.HotelId = val.HotelId;
      this.datafrom.HotelName = val.Name;
      this.hotelShow = false;
    },
    // 获取职位列表
    getAbilityList () {
      GetAbilityList().then((res) => {
        if (res.data.code == 0) {
          // console.log(res.data.data);
          this.AbilityList = res.data.data
        }

      })
    },

    // 选中职位
    onAbilityListFinish ({ selectedOptions }) {
      console.log(selectedOptions);
      this.abilityListShow = false;
      // this.filters.abiName = selectedOptions.map((option) => option.Name).join('/');
      this.datafrom.AbiName = selectedOptions[selectedOptions.length - 1].Name
      this.datafrom.AbilityCode = selectedOptions[selectedOptions.length - 1].Coding
    },
    shopXq: function () {
      console.log(this.$route);
      if (this.$route.params.add == "0") {
        this.datafrom = {
          JobId: 0, //id、
          Title: "",
          AbilityCode: "", // 职业类型编码
          RecruitNum: "", // 招聘人数
          WorkAddrCode: "", // 工作地点编码
          WorkYear: "", // 工作年限
          EduCode: "", // 学历编码
          SalaryMin: "", // 最低月薪
          SalaryMax: "", // 最高月薪
          SalaryMemo: "", // 薪资备注
          AgeMin: "", // 最小年龄
          AgeMax: "", // 最大年龄
          Describe: "", // 职位描述
          Skill: "", // 技能要求
          JobRequire: "", // 任职要求
          Urgency: "", // 紧急程度
          Welfare: "", // 职位福利
          Status: "1",//状态
          Remark: "",//备注
        };
      } else if (this.$route.params.add == "1") {

        // this.GetOrgansNullToken();
        setTimeout(() => {
          HGetJob({
            openID: getOpenId(),
            hotelId: this.$route.params.hotelId,
            jobId: this.$route.params.jobId,
          }).then((res) => {
            this.bangdingShow = true;
            if (res.data.code == 0) {
              this.datafrom = res.data.data;
              this.datafrom.Urgency = res.data.data.Urgency + "";
              this.datafrom.Status = res.data.data.Status + "";
              this.datafrom.AbiName = res.data.data.AbilityName
              var WorkAddrName = []
              if (this.datafrom.WorkAddrCode != '') {
                var WorkAddrNameList = this.datafrom.WorkAddrCode.split(',')
                for (let i = 0; i < WorkAddrNameList.length; i++) {
                  const e = WorkAddrNameList[i];
                  for (let a = 0; a < this.items[0].children.length; a++) {
                    const b = this.items[0].children[a];
                    if (e == b.id) {
                      console.log(b.text);
                      WorkAddrName.push(b.text)
                    }
                  }

                }
              }
              this.datafrom.WorkAddrName = WorkAddrName + '';
              this.datafrom.EduName = res.data.data.EduName;
              this.datafrom.HotelName = res.data.data.HotelName;
            }
          });
        }, 1000);

      }
    },

    // 字典查询
    zidianlist: function () {
      // 商铺类型
      GetCodeEntrysList({ kind: 5 }).then((res) => {
        if (res.data.code == "0") {
          this.eduList = res.data.data;
        }
      });
      // GetCodeEntrysList({ kind: "310" }).then((res) => {
      //   this.shopType = res.data.data;
      // });
    },
    // 确认学历
    edulShowOrg (val) {
      this.datafrom.EduName = val.Name;
      this.datafrom.EduCode = val.Coding;
      this.edulShow = false;
    },
    // 提交
    postfrom: function (row) {
      console.log(this.datafrom);
      if (this.datafrom.HotelName == "") {
        Toast.fail("请选择民宿旅馆");
        return false;
      }
      if (this.datafrom.WorkAddrCode == "") {
        Toast.fail("请选择工作地点");
        return false;
      }
      if (this.datafrom.Title == "") {
        Toast.fail("请输入职位名称");
        return false;
      }
      if (this.datafrom.AbilityCode == "") {
        Toast.fail("请选择职业类型");
        return false;
      }
      if (this.datafrom.RecruitNum == "") {
        Toast.fail("请输入招聘人数");
        return false;
      }
      if (this.datafrom.WorkYear == "") {
        Toast.fail("请输入工作年限");
        return false;
      }

      if (this.datafrom.SalaryMin == "") {
        Toast.fail("请输入最低月薪");
        return false;
      }
      if (this.datafrom.SalaryMax == "") {
        Toast.fail("请输入最高月薪");
        return false;
      }
      if (this.datafrom.AgeMin == "") {
        Toast.fail("请输入最小年龄");
        return false;
      }
      if (this.datafrom.AgeMax == "") {
        Toast.fail("请输入最大年龄");
        return false;
      }
      if (this.datafrom.Urgency == "") {
        Toast.fail("请选择紧急程度");
        return false;
      }
      if (this.datafrom.Status == "") {
        Toast.fail("请选择状态");
        return false;
      }
      console.log(getOpenId());
      this.datafrom.OpenID = getOpenId();


      HSaveJob(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.go(-1);
            // this.$router.push({
            //   name: "register",
            // });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });


    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      // GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
      //   this.OrganList = res.data.data;
      // });
      WeGetOrganTree().then((res) => {
        let resion = res.data.data.map(item => (
          {
            text: item.OName,
            children: item.Children.map(items => ({ id: items.OCode, text: items.OName, }))
          }
        ))
        this.items = resion;
      });
    },
    clickItem (data) {
      // console.log(data);
      this.idListOrg = true
      this.activeIds = []
    },
    postclickItem () {
      console.log(this.activeIds);
      this.WorkAdd = []
      for (let i = 0; i < this.items[0].children.length; i++) {
        const e = this.items[0].children[i];
        for (let a = 0; a < this.activeIds.length; a++) {
          const b = this.activeIds[a];
          if (b == e.id) {
            this.WorkAdd.push(e)
          }
        }

      }
      // console.log(this.WorkAdd);
      this.datafrom.WorkAddrName = this.WorkAdd.map((option) => option.text).join(',');
      this.datafrom.WorkAddrCode = this.WorkAdd.map((option) => option.id).join(',');
      this.idListOrg = false
    },
    // 小区(组)列表
    RegionList: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.go(-1);
    },
    // 社区选择器
    onidListOrg (val) {
      this.datafrom.OCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId (val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow (val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },

    // 消防器材年检日期
    ondatafromOutTime (val) {
      this.datafrom.YearCheckTime = this.dateformat(val);
      this.datafromOutTime = false;
    },
    // 社区
    onocode (val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      // return `${year}-${month}-${day} ${houser}:${Minutes}`;
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}

.yimiao .inputFrom {
  margin: 15px 8%;
}

.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}

.yimiao .buttons {
  text-align: center;
}

.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}

.buttons {
  text-align: center;
}

.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
.filtersBox {
  display: flex;
  border-bottom: 1px solid #ebedf085;
  margin: 0px 10px;
}
.labelClass {
  font-size: 14px;
  font-weight: 500;
  width: 86px;
  color: #646566 !important;
}
</style>